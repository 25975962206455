import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Box } from '../Core';
import {
    Bayut,
    Dubizzle,
    Zameen,
    Olx,
    SectorLabs,
    ZameenDev,
    dubizzleGroup,
} from '../../assets/image/png';
import { device } from '../../utils';
const FooterLogos = styled.div`
    text-align: center;
    a {
        display: inline-block;
        margin: 0 22px 16px;
        width: calc(50% - 44px);
        @media ${device.sm} {
            width: auto;
        }
    }
    img {
        height: 34px;
        filter: brightness(90%);
        &:hover {
            filter: brightness(100%);
        }
    }
`;

const CopyRightArea = styled.div`
    border-top: ${({ dark, theme }) =>
        dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

    padding: 15px 0;
    p {
        color: ${({ dark, theme }) => (dark ? theme.colors.lightShade : theme.colors.darkShade)};
        font-size: 13px;
        font-weight: 300;
        letter-spacing: -0.41px;
        line-height: 38px;
        margin-bottom: 0;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-flex;
        a {
            color: ${({ dark, theme }) =>
                dark ? theme.colors.light : theme.colors.dark} !important;
            font-size: 16px;
            transition: 0.4s;
            padding: 0 3px;
            margin: 0 2.5px;
            &:visited {
                text-decoration: none;
            }
            &:hover {
                text-decoration: none;
                color: ${({ theme, color }) => theme.colors.secondary} !important;
            }
        }
    }
`;

const Footer = ({ isDark = true }) => {
    return (
        <>
            {/* <!-- Footer section --> */}
            <Box bg={isDark ? '#35383c' : 'light'}>
                <Container>
                    <Box
                        css={`
                            padding: 40px 0 32px;
                        `}
                    >
                        <Row className="justify-content-center footer-logos">
                            <Col md="12">
                                <FooterLogos>
                                    <a
                                        href="https://www.dubizzle.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={Dubizzle} alt="dubizzle" />
                                    </a>
                                    <a href="https://bayut.com/" target="_blank" rel="noreferrer">
                                        <img src={Bayut} alt="bayut" />
                                    </a>
                                    <a
                                        href="https://www.zameen.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={Zameen} alt="zameen" />
                                    </a>
                                    <a
                                        href="https://www.olx.com.pk/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={Olx} alt="olx" />
                                    </a>

                                    <a
                                        href="http://zameendevelopments.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={ZameenDev} alt="zameendev" />
                                    </a>
                                    <a
                                        href="https://www.sectorlabs.ro/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={SectorLabs} alt="sectorlabs" />
                                    </a>
                                    <a
                                        href="https://www.empg.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={dubizzleGroup}
                                            alt="dubizzleGroup"
                                            // style={{ height: '28px' }}
                                        />
                                    </a>
                                </FooterLogos>
                            </Col>
                        </Row>
                    </Box>
                    <CopyRightArea dark={isDark ? 1 : 0}>
                        <Row className="align-items-center">
                            <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                                <p>&copy; 2023 Dubizzle Labs. All Rights Reserved.</p>
                            </Col>
                            <Col sm="6" className="text-sm-right text-center">
                                <ul className="social-icons">
                                    <li>
                                        <a
                                            href="https://pk.linkedin.com/company/dubizzlelabs/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="icon icon-logo-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/dubizzlelabs/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="icon icon-logo-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/dubizzlelabs/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <i className="icon icon-logo-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://twitter.com/dubizzlelabs"
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <span style={{ fontSize: '21px', lineHeight: '16px' }}>
                                                &#120143;
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </CopyRightArea>
                </Container>
            </Box>
        </>
    );
};

export default Footer;
