import React from 'react';
import { Link } from 'gatsby';

import imgL1Logo from '../../assets/image/png/DUBIZZLELABS-FULL-COLOUR.png';
import imgL1LogoWhite from '../../assets/image/png/DUBIZZLELABS-WHITE.png';

const Logo = ({ white, height, className = '', ...rest }) => {
    return (
        <Link to="/" className={`${className}`} {...rest}>
            {white ? (
                <img src={imgL1LogoWhite} alt="EMPG Logo" />
            ) : (
                <img src={imgL1Logo} alt="EMPG Logo" width="100%" />
            )}
        </Link>
    );
};

export default Logo;
